.bg-primary-light {
  background: #E0E0E0;
}

.bg-property-page {
  background: #CED7D3;
}

.bg-secondary-dark {
  background: #F5F2F1;
}

.bg-aside-form {
  border-radius: 7px;
  background: #ECEFEE;
}

.bg-main {
  background: #5D7A6B
}

.icon-s {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.bg-light-2 {
  border-radius: 8px;
  background: rgba(207, 216, 211, 0.4);
}

.bg-green-light {

  background: #B2C3BA;
}

.banner-image {
  height: 350px;
  object-fit: cover;
  background: #ced7d3;

  &.dark-bg {

  }
}

.bg-secondary-light {
  background: #E1E1E1;
}

.box-shadow {
  border-radius: 10px;
  background: #E1E1E1;
  box-shadow: 0px 4px 132px 0px rgba(15, 39, 37, 0.31);
}

.bg-green-secondary {
  border-radius: 6px 6px 0px 0px;
  background: #5B7869;
}

.bg-form {
  background: #A6B6AE;
}

.bg-s {
  background: #E8EBEA;
}

.banner {
  width: 100%;
  height: 700px;
  object-fit: cover;
  filter: brightness(0.7);
}

.bg-grey {
  background: #c5cccb
}

.bg-testimonial {
  background: rgba(93, 122, 107, .847);
}

.bg-about {
  background-color: #BDC6C4;
}

.bg-about-2 {
  background-color: #5B7869;
}

.bg-contact {
  background-color: #F1F1F1 !important;
}