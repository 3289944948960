.fs-17 {
  font-size: 17px;
  line-height: 21px;
}

.fs-18 {
  font-size: 18px;
  line-height: 22px;
}

.fs-20 {
  font-size: 20px;
  line-height: 24px;
}

.fs-19 {
  font-size: 19px;
  line-height: 23px;
}

.fs-14 {
  font-size: 14px;
  line-height: 18px;
}

.fs-sm {
  font-size: 12px;
  line-height: 16px;
}

.fs-35 {
  font-size: 35px;
}

.fs-31 {
  font-size: 31px;
  line-height: 40px;
}

.fs-16 {
  font-size: 16px !important;
  line-height: 20px;
}

.text-primary {
  color: $primary !important;

  a:hover {
    color: $white
  }
}

.text-transform-0 {
  text-transform: unset !important;
}

.error {
  color: $error-text-color;
}


.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
  line-height: 16px;
}

.fs-25 {
  font-size: 25px;
}

.fs-20 {
  font-size: 20px !important;
}

.line-height-24 {
  line-height: 24px !important;
}

.text-end {
  text-align: end;
}

.text-primary {
  color: $primary;

  &:hover {
    color: $lighter-color;
  }
}

.f-32 {
  font-size: 32px;
}

.f-r {
  font-family: $font-medium;
}

.f-s {
  font-family: $font-main-title;
}

.fs-75 {
  font-size: 75px;
}

.fs-65 {
  font-size: 65px;
}

.fs-15 {
  font-size: 15px;
  line-height: 19px;
}

.text-color {
  color: $text-color;
}

.text-main {
  color: #0F2725;
}

.dark-primary {
  color: $dark-primary;
}

.offplan-description-main {
  color: #ADBBB4;
  font-family: $font-regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
}

.main-description {
  color: #2C2E34;
  text-align: center;
  font-family: $font-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@each $name, $value in $sizes {
  .fs-#{$name} {
    font-size: #{$value}px;
  }
  .lh-#{$name} {
    line-height: #{$value}px;
  }

}

@each $name, $value in $font-families {
  .f-#{$name} {
    font-family: #{$value} , serif;
  }
}

//.title-sm{
//  color:  #0F2725;
//  text-align: start;
//  font-family: $font-main-title;
//  font-size: 30px;
//  font-style: normal;
//  font-weight: 700;
//  line-height: normal;
//}
.description-sm {
  color: #2C2E34;
  font-family: $font-regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  opacity: 0.5;
}

.title-smm {
  color: #0F2725;
  text-align: start;
  font-family: $font-main-title;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.article-description {
  color: #959699;
  font-family: $font-regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;

  p, span, div {
    color: #959699;
    font-family: $font-regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }

  a {
    text-decoration: underline;
    color: #9B736A;
    cursor: pointer;

    span {
      text-decoration: underline;
      color: #9B736A;
      cursor: pointer;
    }

    &:hover {
      text-decoration: underline !important;
      cursor: pointer;
    }
  }
}

.bg-t-ch, .img-t-ch {
  border-radius: 50%;
  height: 140px;
  width: 140px;
  background: #D8C6C2;

}

.img-t-ch {
  padding: 7px;
}

.name {
  color: #0F2725;
  font-family: $font-regular;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.last-name {
  color: #5D7A6B;
  font-family: $font-regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 230.769% */
}

.th-description {
  color: #000;
  font-family: $font-regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  opacity: 0.5;

}

.property-type {
  color: #2C2E34;
  font-family: $font-regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  opacity: 0.5;
}

.property-name {
  color: #0F2725;
  font-family: $font-main-title;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.property-location {
  color: #6B6D71;
  font-family: $font-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

}

.property-price {
  color: #A57E75;
  font-family: $font-main-title;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.select-price-label {
  color: #6B6D71;
  font-family: $font-regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.main-text {
  color: #6B6D71;
  font-family: $font-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  &.bolder {
    font-weight: 700;
  }

  &.sm {
    font-size: 13px;
  }

  &.light {
    color: #959799;
  }
}

.pl-f {
  color: #0F2725;
  font-family: $font-main-title;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dld-nb {
  background: #EFF2F0;
  color: #5D7A6B;
  border-radius: 5px;
  display: inline-flex;
  padding: 7px 20px;
  font-family: $font-main-bold;
}

.select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 15px;
  outline: none;
  color: #2C2E34;
}

.select:hover {
  border-color: #999; /* Change border color on hover */
}

.select:focus {
  border-color: #007bff; /* Change border color when focused */
}

.secondary-title {
  color: #A57E75;
  font-family: $font-regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 156% */
}

.info-title {
  color: #5D7A6B;
  font-family: $font-regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px; /* 156% */
}

.dark-title {
  color: #0F2725;
  font-family: $font-regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px; /* 156% */
}

.form-title {
  color: #0F2725;
  font-family: $font-main-title;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.text-none {
  text-transform: none;
}

.table-container {
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-x: auto;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  /* Define styles for table cells */

  padding: 8px; /* Example padding */
}

@media (max-width: 768px) {
  .custom-table, .custom-table tbody, .custom-table tr, .custom-table td {
    display: block;
    width: 100%;
  }

  .custom-table tr {
    margin-bottom: 10px;
  }

  .custom-table td {
    position: relative;
    text-align: center;
  }


}

.text-green-main {
  color: #5D7A6B;
  font-family: $font-secondary-regular;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-brown-main {
  color: #A57E75;
  font-family: $font-main-title;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-sm-grey {
  color: #2C2E34;
  font-family: $font-regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  opacity: 0.5;
}

.title-sm {
  color: #0F2725;
  font-family: $font-main-title;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.title-secondary {
  color: #0F2725;
  font-family: $font-secondary-regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.sm-nb {
  color: #959799;
  font-family: $font-regular;
  font-size: 13px;
  font-style: normal;
  //font-weight: 600;
  line-height: 17px; /* 166.667% */
}

.fs-15 {
  font-size: 15px;
}

.fs-17 {
  font-size: 17px;
}

.contact-title {
  color: #5D7A6B;
  font-family: $font-main-title;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px; /* 114.286% */
}

.contact-sm {
  color: #5D7A6B;
  font-family: $font-regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.property-nb {
  color: #2C2E34;
  font-family: $font-regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
}

.property-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.property-list li {
  position: relative;
  padding-right: 12px;
  font-size: 12px;
  font-family: $font-main-bold;
  color: #000;

  span {
    color: #5D7A6B;
  }
}

.property-list li:not(:last-child)::after {
  content: "|";
  position: absolute;
  right: 0;
  top: 0;
}

.location {
  color: #6B6D71;
  font-size: 14px;
}

.title-2 {
  color: #19302d;
}

.text-light-dark {
  color: #fff;
}

.dark-note {
  color: #0F2725;
  font-family: $font-main-bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.tags {
  list-style: none;
  display: inline-flex;
  color: #4D605D;
  font-family: $font-regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  flex-wrap: wrap;
  padding-left: 0;

  li {
    a {
      margin: 2px 5px;
      background: #DFE4E1;
      border-radius: 6px;
      padding: 8px 10px;

      &:hover {
        color: #DFE4E1;
        background: #4D605D;
      }
    }


  }
}

.title-header {
  color: #183031;
  text-align: center;
  font-family: $font-secondary-regular;
  font-size: 43px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  text-transform: capitalize;
}

.title-main {
  color: #0F2725;
  font-family: $font-secondary-regular;
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fs-34 {
  font-size: 34px;
}

.description-2 {
  color: #0F2725;
  text-align: center;
  font-family: $font-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 166.667% */
  text-transform: capitalize;
}

.light-grey {
  color: #959799
}

.text-main {
  color: #0F2725;
}

.form-label-secondary {
  color: #0F2725;
  font-family: $font-main-title;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.interested {
  color: #0F2725;
  font-family: $font-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-main-lighter {
  color: #1A3030
}

.p-lighter {
  color: #1A3030;
  text-align: center;
  font-family: $font-regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.subscribe-input {
  border-radius: 7px;
  border: unset !important;
  background: #FFF;
  color: #AEB4B4;
  font-family: $font-regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.subscribe-form {
  border-radius: 7px;
  position: absolute;
  bottom: 11%;
  width: 90%;
  background: #fff;
  padding: 8px;

}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.fs-24 {
  font-size: 24px;
  line-height: 30px;
}

.fs-18 {
  font-size: 18px;
}

.fs-sm-14 {
  @media (max-width: $breakpoint-767) {
    font-size: 14px !important;
  }
}

.f-main-medium {
  font-family: $font-main-medium;
}

.lh-32 {
  line-height: 32px;
}

.lh-sm-25 {
  @media (max-width: $breakpoint-767) {
    line-height: 25px;
  }
}

.color-bl {
  color: #2C2E34;
}

.color-gr {
  color: #0F2725;
}

.fs-53 {
  font-size: 53px;
}

.fs-sm-45px {
  @media (max-width: $breakpoint-767) {
    font-size: 45px;
  }
}

.lh-55px {
  line-height: 55px;
}

.lh-sm-45px {
  @media (max-width: $breakpoint-767) {
    line-height: 45px;
  }
}