.border-black {
  //border: 1px solid #000;
  opacity: 1;
}

.bdr-light-grey {
  border: 1px solid #D9D9D9;
}

.bdr-2 {
  border-radius: 2px
}

.bdr-t-10 {
  border-radius: 10px 10px 0 0;
}

.bdr-b-10 {
  border-radius: 0 0 10px 10px;
}

.bdr-t-6 {
  border-radius: 6px 6px 0 0;
}

.bdr-b-6 {
  border-radius: 6px 6px 0 0;
}
