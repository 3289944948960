.description-main {
  color: #2C2E34;
  text-align: center;
  font-family: $font-regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 175% */
}

.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;

  .page-item {
    margin: 0 5px;
  }

  .page-link {
    box-shadow: unset !important;
    border-radius: 3px;
    padding: 5px 10px;
    border: 1px solid #F6F2F1;
    background-color: #fff;
    color: #B8C4BE;
    font-family: $font-main-bold;
    font-size: 15px;
    font-style: normal;
    line-height: normal;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f0f0f0;
    }

    &.active {
      border-radius: 3px;
      background: #A57E75;
      color: #fff;
      border-color: #F6F2F1;
    }
  }

  .arrow-prev,
  .arrow-next {
    &.disabled {
      opacity: 0.3;
    }

    img {
      width: 20px;
    }


    border: unset !important;


    &:hover {
      background-color: transparent !important;
    }
  }

  .arrow-prev {
    margin-right: 5px;
  }

  .arrow-next {
    margin-left: 5px;
  }
}

.team-name {
  color: #0F2725;
  text-align: center;
  font-family: $font-main-title;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.team-position {
  color: #396C52;
  text-align: center;
  font-family: $font-main-bold;
  font-size: 12px;
  font-style: normal;
  line-height: 20px;
  text-transform: uppercase;
}

.bg-team {
  background: #FAFBFB;
}

.hover-darker:hover {
  background: #E5E8E8;

}

.cursor-pointer {
  cursor: pointer;
}

.offplan-placeholder {
  position: absolute;
  top: 10%;
  right: 0;
  z-index: 1
}

.z-index-999 {
  z-index: 999;
}
