input, textarea {
  outline: none !important;
}

* {
  outline: none !important;
}

html {
  position: relative;
  min-height: 100%;
  background: $white;

}

body {

  font-family: $font-medium;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: $black;
  margin-bottom: 25rem;


  @media (max-width: $breakpoint-767) {
    margin-bottom: 0;
  }
}

h1, h2, h3 {

}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  color: inherit;
}

textarea {
  resize: none;
}

.w-100 {
  width: 100%;
}

.text-center-991 {
  @media (max-width: $breakpoint-991) {
    text-align: center;
  }
}

.hide-767 {
  @media (max-width: $breakpoint-767) {
    display: none;
  }
}

.show-767 {
  display: none;

  @media (max-width: $breakpoint-767) {
    display: inline-block;
  }
}

.row-eq-height {
  @media (min-width: 992px) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.text-lines {
  color: #000;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;

  &:before,
  &:after {
    content: "";
    background-color: #000;
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
}

.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.col-centered {
  float: none;
  margin: 0 auto;
}


.content {
  text-align: center;
  margin-bottom: 35px;
}

.no-transition {
  @include transition(none);
}

//body.home {
//    background-color: $main-color;
//}
.bdr-t {
  border-radius: 8px 8px 0 0;
}

.bdr-lg-tb {
  border-radius: 15px 15px 8px 8px;
}

.bdr-b {
  border-radius: 0 0 8px 8px;
}

.h-125px {
  height: 125px;
  object-fit: cover;
}

.h-127px {
  height: 127px;
  object-fit: cover;
}

.h-140px {
  height: 140px;
  object-fit: cover;
}

.h-180px {
  height: 180px;
  object-fit: cover;
}

.h-160px {
  height: 160px;
  object-fit: cover;
}

.h-150px {
  height: 150px;
  object-fit: cover;
}

.h-200px {
  height: 200px;
  object-fit: cover;
}

.h-250px {
  height: 250px;
  object-fit: cover;
}

.h-280px {
  height: 285px;
  object-fit: cover;
}

.h-300px {
  height: 300px;
  object-fit: cover;
}

.h-500px {
  height: 500px;
  object-fit: cover;
}

.h-400px {
  height: 400px;
  object-fit: cover;
}

.h-450px {
  height: 450px;
  object-fit: cover;
}


.h-350px {
  height: 350px;
  object-fit: cover;
}

.h-300px {
  height: 300px;
  object-fit: cover;
}

.icon-sm {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.h-none {
  .css-13cymwt-control {
    height: unset !important;
  }
}

.css-13cymwt-control {
  border: unset;
  padding-left: 0;
  height: 58px;
  border-radius: 8px;
  border: 1px solid #C5CCCB;
  background: #FFF;
}

//.resale{
//    .css-t3ipsp-control{
//        border: unset;
//        padding-left: 0;
//        height: 58px;
//        border-radius: 8px;
//        border: 1px solid #C5CCCB;
//        background: #FFF;
//    }
//}

.h-400px {
  height: 500px
}

.object-fit-contain {
  object-fit: contain;
}

.centered-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
}

.fs-55 {
  font-size: 55px;
}

.benefit-img {
  height: 50px;
  object-fit: contain;
}

.aside-placeholder {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);

  img {
    width: 250px
  }
}

.aside-placeholder-left {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);

  img {
    width: 250px
  }
}


.radio-2 {
  display: block;
  cursor: pointer;
  user-select: none;
  text-align: left;

  & + .radio {
    margin-top: 12px;
  }

  input {
    display: none;

    & + span {
      display: inline-block;
      position: relative;
      padding-left: 30px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        border: 1px solid #ABABAB;
        background: #fff;
      }

      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background: #A57E75;
        position: absolute;
        border-radius: 50%;
        top: 3px;
        left: 3px;
        border: 1px solid #A57E75;
        opacity: 0.4;
        transform: scale(0, 0);
        transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }
    }

    &:checked + span:after {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
}

.radio {
  display: block;
  cursor: pointer;
  user-select: none;
  text-align: left;

  & + .radio {
    margin-top: 12px;
  }

  input {
    display: none;

    & + span {
      display: inline-block;
      position: relative;
      padding-left: 30px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        border: 1px solid #ABABAB;
        background: #fff;
      }

      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background: #A57E75;
        position: absolute;
        border-radius: 50%;
        top: 3px;
        left: 3px;
        border: 1px solid #A57E75;
        opacity: 0;
        transform: scale(0, 0);
        transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }
    }

    &:checked + span:after {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
}

/* END CHECKBOX STYLE */


.flex-wrap{
  flex-wrap: wrap;
}
.bdr-7{
  border-radius: 7px;
}
.newsletter-img{
  height: 290px;
  object-fit: cover;
}