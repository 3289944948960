@media (max-width: 992px) {
  .video-wrapper{
    height: 470px;
    .video{
      height: 100%;
    }
  }

  .subscribe-form {
    position: unset;
    width: 100%;
    padding:0;


  }
  .bdr-7-md{
    border-radius: 7px;
  }
  .newsletter-img{
    height: 160px;
  }
  .popup{
    h4{
      font-size: 16px;
      line-height: 20px;
    }
    p{
      font-size: 13px;
      line-height: 17px;
    }

    .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  .h-160px{
    height: auto;
  }
  .navbar-collapse{
    background: #fff;
  }
  .title-header {
    font-size: 27px;
    line-height: 31px;
  }
  .title-main {
    font-size: 18px;
    line-height: 22px;
  }
  .fs-65{
    font-size: 35px;
    line-height: 39px;
  }
  .fs-40{
    font-size: 25px;
    line-height: 29px;
  }
  .fs-24{
    font-size: 17px;
    line-height: 21px;
  }
  .testimonial{
    .block-center{
      position: unset;
      transform: unset;
      width: 100%;
    }

    .carousel-control-prev{
      left:0;
      top: unset !important;
      bottom: 2%;
    }
    .carousel-control-next{
      right: 0;
      top: unset !important;
      bottom: 2%;
    }
  }
  .popup {
    position: fixed;
    bottom: 10%;
    right: 14px;
    width: 93%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-pagination .pagination .next {
    top: unset !important;
    transform: unset !important;
  }
  .custom-pagination .pagination .previous {
    top: unset !important;
    transform: unset !important;
  }
  .custom-ar {
    img {
      width: 20px;
      height: 20px;
    }

  }


  .resale-pagination {
    .pagination {
      .next {
        .custom-ar {
          display: none;
        }
      }

      .previous {
        .custom-ar {
          display: none;
        }
      }
    }
  }
  .storySection {
    .carousel-control-next {
      right: 0 !important;
    }

    .carousel-control-prev {
      left: 0 !important;
    }

  }
  .description-sm {
    font-size: 16px;
    line-height: 20px;
  }
  .date {
    font-size: 14px !important;
  }
  .property-header {
    .navbar-collapse {
      position: absolute;
      width: 100%;
      left: 0;
      text-align: center;
      z-index: 19999999;
      top: 74px;
    }
  }
  .btn.btn-primary.lg {
    font-size: 13px !important;
  }
  .resale .carousel {
    height: auto !important;
  }
  .banner-image {
    height: 300px;
    object-fit: cover;

    &.dark-bg {

    }
  }
  .h-125px {
    height: auto;
  }
  .h-127px {
    height: auto;
  }
  .h-140px {
    height: auto;
  }
  .h-150px {
    height: auto;
  }
  .h-200px {
    height: auto;
  }
  .h-250px {
    height: auto;
  }
  .h-280px {
    height: auto;
  }
  .h-500px {
    height: auto;
  }

  .h-400px {
    height: auto;
  }

  .h-450px {
    height: auto;
  }
  .h-350px {
    height: auto;
  }
  .h-300px {
    height: auto;
  }
}