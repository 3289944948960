
.testimonial{

  .carousel-indicators{
    display: none;
  }
  .carousel-control-prev{
    left: -14%;
    background: transparent;
  }
  .carousel-control-next{
    right: -14%;
    background: transparent;
  }
}

.storySection {
  .carousel-caption {
    position: unset !important;
    transform: unset !important;
  }

  .carousel-inner {
    background: rgba(93, 122, 107, .847);

    .successBox {
      //height: 330px;
      //max-height: 330px;

      //overflow-y: auto;

      //min-height: 500px;

      display: flex;
      justify-content: center; /* Centers horizontally */
      align-items: center; /* Centers vertically */
      height: 100%; /* Make sure the height is set or inherited */
    }

  }
}

.carousel-caption {
  bottom: unset !important;
  left: unset !important;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

.property {
  .carousel.carousel-slider {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

  .carousel .thumbs-wrapper {
    margin: 10px 30px;
  }

  .control-dots {
    display: none;
  }

  .carousel .thumbs {
    padding-left: 0;
  }

  //.carousel-container {
  //  position: relative;
  //}
  //
  //.custom-arrow {
  //  position: absolute;
  //  bottom: 10px; /* Adjust as needed */
  //  width: 40px; /* Adjust width of arrow images */
  //  height: auto; /* Maintain aspect ratio */
  //  z-index: 1; /* Ensure arrows are above the carousel */
  //  cursor: pointer;
  //}
  //
  //.custom-arrow-prev {
  //  left: 10px; /* Adjust distance from left */
  //}
  //
  //.custom-arrow-next {
  //  right: 10px; /* Adjust distance from right */
  //}
  .carousel-container {
    position: relative;
  }

  .custom-arrow {
    background: transparent;
    border: unset;
    position: absolute;
    top: 50%;
    cursor: pointer;
    z-index: 999;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .control-arrow {
    display: none;
  }

  .custom-arrow-prev {
    left: 0; /* Adjust as needed */
  }

  .custom-arrow-next {
    right: 0; /* Adjust as needed */
  }

}

.testimonial-carousel-img {
  width: 20px !important;
}

.custom-carousel {
  .dots-container {
    position: absolute;
    bottom: 2%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 100%;
    text-align: center;

    .dot {
      height: 10px;
      width: 10px;
      margin: 0 2px;
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 50%;
      display: inline-block;
      transition: background-color 0.3s ease;
      cursor: pointer;
    }

    .dot.active {
      background-color: #ffffff;
    }
  }

}

.spinner {
  background: white;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #333;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.custom-arrow {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;
  transition: opacity 0.3s ease;
}

.custom-arrow:disabled img {
  filter: grayscale(100%);
}

.images-wrapper {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.main-image {
  width: 75%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.sidebar {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.sidebar-image {
  width: 100%;
  height: 50%;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
}

.popup {
  position: fixed;
  bottom: 0;
  right: 14px;
  width: 600px;
  max-width: 600px;
  //height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.object-fit{
  object-fit: cover;
}
.popup-content {
  padding: 20px;
  border-radius: 16px 16px 0px 0px;
  border: 3px solid #FFF;
  background: #E8E8E8;
  box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.25);
}

.close {
  position: absolute;
  top: -10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  background: black;
  color: white;
  height: 30px;
  width: 30px;
  padding: 2px 9px;
  border-radius: 50%;
  border: 2px solid #fff;
}