.faq-section {
  padding: 50px 0;

  @media (max-width: $breakpoint-767) {
    padding: 35px 0;
  }

  .btn-faq {
    background-color: #5D7A6B;
    border-radius: 8px;
    border: 2px solid #5D7A6B;
    color: #ffffff;
    font-family: $font-main-bold !important;
    font-size: 20px !important;
    line-height: 24px !important;
    padding: 10px 25px;
    transition: all 0.2s;

    @media (max-width: $breakpoint-767) {
      font-size: 16px !important;
      line-height: 20px !important;
      padding: 5px 8px;
    }

    &.btn-faq-collapse {
      background-color: #ffffff;
      color: #5D7A6B;

      &:hover {
        background-color: #5D7A6B;
        color: #ffffff;
      }
    }

    &:hover {
      background-color: #ffffff;
      color: #5D7A6B;
    }
  }

  .accordion {
    margin: 40px 0;

    @media (max-width: $breakpoint-767) {
      margin: 25px 0;
    }

    .accordion-item {
      border-radius: 4px !important;
      margin-bottom: 25px;
      border-width: 1px !important;
      border: 1px solid #5D7A6B !important;
      border-color: rgba(93, 122, 107, 0.27) !important;

      @media (max-width: $breakpoint-767) {
        margin-bottom: 15px;
      }

      .accordion-header {
        background-color: #F1F1F1 !important;

        button {
          background-color: #F1F1F1 !important;
          box-shadow: none !important;
          font-family: $font-main-title !important;
          font-size: 27px !important;
          line-height: 31px !important;
          color: #0F2725 !important;
          padding: 25px 45px;

          @media (max-width: $breakpoint-767) {
            font-size: 18px !important;
            line-height: 20px !important;
            padding: 10px 15px;
          }

          &:focus {
            border-color: rgba(93, 122, 107, 0.27) !important;
          }

          &:not(.collapsed) {
            border-bottom: 1px solid #5D7A6B !important;
            border-color: rgba(93, 122, 107, 0.27) !important;

            &:after {
              background-image: var(--bs-accordion-btn-icon) !important;
            }
          }
        }
      }

      .accordion-body {
        font-family: $font-main-medium;
        font-size: 18px !important;
        line-height: 34px !important;
        color: #2C2E34 !important;
        padding: 25px 45px !important;

        @media (max-width: $breakpoint-767) {
          font-size: 14px !important;
          line-height: 20px !important;
          padding: 10px 15px !important;
        }
      }
    }
  }
}