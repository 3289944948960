@font-face {
  font-family: "Actor Regular";
  src: url("../../fonts/Actor-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand Regular";
  src: url("../../fonts/Quicksand-Regular.ttf") format("opentype");
  src: url("../../fonts/Quicksand-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand Medium";
  src: url("../../fonts/Quicksand-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand Bold";
  src: url("../../fonts/Quicksand-Bold.ttf") format("opentype");
  src: url("../../fonts/Quicksand-Bold.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../../fonts/Poppins-Bold.ttf") format("opentype");
  src: url("../../fonts/Poppins-Bold.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Palatino Linotype";
  src: url("../../fonts/pala.ttf") format("opentype");
  src: url("../../fonts/pala.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Palatino Linotype Bold";
  src: url("../../fonts/palab.ttf") format("opentype");
  src: url("../../fonts/palab.ttf") format("opentype");
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "High Spirited";
  src: url("../../fonts/HighSpirited.ttf") format("opentype");
  src: url("../../fonts/HighSpirited.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
