.separator-mini-2-s {

}

.separator-mini-2-secondary {
  border-bottom: 4px solid $secondary;
  line-height: 0.1em;
  margin: 10px 0 20px;
  width: 38px;
}

.separator-mini-secondary {
  border-bottom: 5px solid $secondary;
}

.separator-mini-2 {
  border-bottom: 4px solid $white;
  line-height: 0.1em;
  margin: 10px 0 20px;
  width: 38px;
}

.separator-mini {
  border-bottom: 4px solid $white;
}

.separator {
  border: 1px solid #C9CBCB;
  opacity: 1
}

.separator-2 {
  border: 1px solid #537062;
  opacity: 1;
}