footer {
  overflow: hidden;
  background-color: #fff !important;
  padding-top: 2rem;
  padding-bottom: 1rem;

  .footerGutter {
    --bs-gutter-x: 5rem;
  }

  .footerLinks {
    font-family: $font-regular;
    list-style: none;
    color: #2c2e34;
    font-weight: bold;

    li {
      margin-top: 1rem;
    }
  }

  .copySocial {
    .small {
      color: #878787;
      //margin: 2rem 0;
      //width: 50%;
    }

    .socialLinks {
      list-style: none;
      display: inline-flex;
      padding-left: 0;

      li {
        margin-right: 1rem;

        a {
          font-weight: 600;
        }

        i {
          background-color: rgba(93, 122, 107, 0.55);
          color: #fff;
          padding: 7px;
          border-radius: 50%;
          height: 30px;
          width: 30px;
          text-align: center;

          &:hover {
            background-color: rgb(93, 122, 107);
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .nav-link {
    color: #0F2725 !important;
  }
  .footerLinks {
    padding-left: 0;
  }
}
