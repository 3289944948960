.top-banner{
  position: relative;
  .video-wrapper{
    width: 100%;
    .video{
      filter: brightness(.5);
      object-fit: cover;
    }
  }
}


.block-center{
  position: absolute;
  top:44%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 95%;
}
.fs-65{
  font-size: 65px;
  line-height: 70px;
}

.fw-700{
  font-weight: 700
}
.fs-40{
  font-size: 40px;
  line-height: 44px;
}
.signature{
  .first{
    font-family: High Spirited;
  }
}
.overlay-right{
  height: 100%;
  position: absolute;
  right: 5%;
  top: 0;
}

.dark-light-color{
  color: $dark-light
}
.lh-25{
  line-height: 25px;
}

.resale {
  background: url("/assets/images/resale-bg.png");

  .css-13cymwt-control {
    border-radius: 4px;
    border: 1px solid rgba(93, 122, 107, 0.27);
    background: #F1F1F1;

  }

  .css-t3ipsp-control {
    height: 58px;
  }
}

.icons-n {
  cursor: pointer;
  margin-right: 0.3rem;
  font-size: 18px;
  border: 1px solid #ccc;
  background-color: rgba(93, 122, 107, 0.2);
  border-radius: 16px;
  padding: 3px;
  display: inline-block;
  list-style: none;
  transition: all .3s;
  text-align: center;
  width: 30px;
  position: relative;
  color: #5d7a6b;
  transition: all .3s;

  &:hover {
    background-color: rgba(93, 122, 107, 0.55);
  }

  i {
    font-size: 15px;
  }
}

.offplan {
  .css-13cymwt-control {
    border-radius: 4px;
    border: 1px solid rgba(93, 122, 107, 0.27);
    background: #F1F1F1;
  }

  .css-t3ipsp-control {
    height: 58px;
  }
}

.card {
  border-radius: 0;
  border: transparent;
}

.card.card-custom.card-stretch.gutter-b {
  height: calc(100% - 30px);
}

.card.card-custom.card-stretch {
  display: flex;
  align-items: stretch !important;
  flex-direction: column;
  height: 100%;
}

.example.example-compact {
  position: relative;
}

.gutter-b {
  margin-bottom: 30px;
}
.hover-opacity{
  &:hover{
    opacity: .6;
  }

}
.quote {
  width: 35px !important;
  margin-bottom: 1rem;

}
.overlay-left{
  height: 100%;
  left: 18%;
  position: absolute;
  top: -30%;
}
.bdr-left-lg{
  border-top-left-radius: 80px;
  position: relative;
}

.bdr-all {
  border-radius: 7px;
}
.project-status {
  background: linear-gradient(180deg, #0f2725, rgba(18, 52, 49, .87));
  border-radius: 11px;
  color: #fff;
  font-size: 13px;
  padding: 4px 17px;
}