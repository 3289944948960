.contact-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
  width: 100%;
}

.contact-item {
  display: flex;
  align-items: flex-start; /* Align the icon and text at the top */
  font-size: 16px;
  color: #333;
  word-wrap: break-word; /* Ensure long text breaks within the available space */
  width: 100%;
  margin-bottom: 25px;
}

.contact-item i {
  margin-right: 15px;
  font-size: 18px;
  line-height: 18px;
  flex-shrink: 0;
}

.contact-item a {
  text-decoration: none;
  font-size: 18px;
  line-height: 22px;
  color: #0F2725;
  font-family: $font-main-title;
}

.contact-item a,
.contact-item span {
  flex: 1;
  word-break: break-word; /* Break long words to fit within the container */
  line-height: 1.5;
}

.contact-item a:hover {
  text-decoration: underline;
}

.contact-item span {
  font-size: 18px;
  line-height: 22px;
  color: #0F2725;
  font-family: $font-main-title;
}

.bg-contact {
  border-radius: 4px;
  border: 1px solid #5D7A6B;
  border-color: rgba(93, 122, 107, 0.27) !important;

  form {
    .form-label {
      font-family: $font-main-medium;
      color: #2C2E34;
      opacity: 0.5;
      font-size: 17px;
      line-height: 20px;
    }

    .form-control {
      font-family: $font-regular;
      color: #2C2E34;
      font-size: 16px;
      line-height: 20px;
      border-radius: 5px !important;
    }
  }
}