$breakpoint-1199: 1199px;
$breakpoint-991: 991px;
$breakpoint-767: 767px;
$breakpoint-575: 575px;

$font-main-title: "Palatino Linotype Bold", serif;
$font-regular: "Quicksand Regular", serif;
$font-main-bold: "Quicksand Bold", serif;
$font-main-medium: "Quicksand Medium", serif;
$font-secondary-regular: "Actor Regular", serif;
$font-bold: "Poppins Bold", serif;
$font-semi-bold: "Poppins SemiBold", serif;
$font-extra-bold: "Poppins ExtraBold", serif;
$font-medium: "Poppins Medium", serif;
$font-black: "Poppins Black", serif;

$base-fontsize: 16;
$error-text-color: #dc3545;
$primary: #5d7a6b;
$gradient: #5d7a6b;
$dark-primary: #5d7a6b;
$secondary: #5d7a6b;
$text-color: #4a4a4a;
$lighter-color: lighten($primary, 15%);
$grey: #c5cccb;
$dark: #2c2e34;
$choco: #a57e75;
$olive: #5d7a6b;

$dark-light: #2c2e34;

$sizes: (
        "0": 0,
        "10": 10,
        "11": 11,
        "12": 12,
        "13": 13,
        "14": 14,
        "15": 15,
        "16": 16,
        "17": 17,
        "18": 18,
        "19": 19,
        "20": 20,
        "24": 24,
        "25": 25,
        "27": 27,
        "28": 28,
        "30": 30,
        "34": 34,
        "35": 35,
        "36": 36,
        "38": 38,
        "40": 40,
        "45": 45,
        "50": 50,
        "54": 54,
        "55": 55,
        "60": 60,
        "70": 70,
        "80": 80,
        "100": 100,
        "110": 110,
        "120": 120,
        "130": 130,
        "140": 140,
        "150": 150,
        "160": 160,
        "170": 170,
        "180": 180,
        "190": 190,
        "200": 200,
        "240": 240,
        "250": 250,
        "300": 300,
        "340": 340,
) !default;

$font-families: (
        "regular": $font-regular,
        "black": $font-black,
        "bold": $font-bold,
        "extra-bold": $font-extra-bold,
        "semi-bold": $font-semi-bold,
        "medium": $font-medium,
        "secondary": $font-main-title,
) !default;

@each $name, $value in $sizes {
  .w-#{$name}px {
    width: #{$value}px;
  }
  .mt-#{$name} {
    margin-top: #{$value}px;
  }
  .mb-#{$name} {
    margin-bottom: #{$value}px;
  }
  .mr-#{$name} {
    margin-right: #{$value}px;
  }
  .ml-#{$name} {
    margin-left: #{$value}px;
  }

  .m-#{$name} {
    margin: #{$value}px;
  }
  .p-#{$name} {
    padding: #{$value}px;
  }
}


