header {
  position: relative;

  .navbar {
    position: absolute;
    z-index: 9999;
    width: 95%;
    padding-bottom: 0;

    .navContainer {
      // flex-direction: column;
      display: flex;
      justify-content: space-between;

      .navbar-brand {
        padding: 1rem 0;

        img {
          filter: brightness(1.5);
        }
      }

      .navbar-toggler {
        border: none;
      }

      .navbar-nav {
        width: 100%;
        justify-content: end;

        .nav-link {
          // background: rgba(197, 204, 203, 0.28);
          // opacity: 0.8;
          padding: 0 10px;
          // border-radius: 8px 8px 0px 0px;
          // padding: 1rem;
          // margin: 0 1px;
          color: #fff;
          font-weight: 500;

          &:hover,
          &.active {
            // background-color: #5d7a6b;
            // color: #fff;
            opacity: 0.8;
            // border-bottom: 1px solid #fff;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
          }
        }
      }
    }
  }

  .opacity {
    background-color: rgba(221, 226, 225, 0.7) !important;
  }
}

@media (max-width: 991px) {
  .navbar {
    .navContainer {
      flex-direction: row;

      .navbar-brand {
        margin: 0 !important;

        .logo {
          width: 200px;
        }
      }

      .navbar-toggler {
        position: absolute;
        top: 30px;
        right: 20px;

        &:focus {
          box-shadow: none;
        }
      }

      .navbar-nav {
        .nav-link {
          margin-bottom: 5px;

        }
      }
    }
  }
}

.property-header {
  background: #ffffff;
  position: unset !important;
  width: 100%;

  .nav-link {
    color: #0F2725 !important;
    font-family: $font-regular;

    &:hover {
      opacity: 0.8;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    &.active {
      font-family: $font-main-bold;
      color: #0F2725 !important;

    }
  }

  .btn-bordered-white {
    border: 1px solid #0F2725 !important;
    padding: 10px !important;

  }

  .navbar-toggler {
    color: #0F2725;
  }
}