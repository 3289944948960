.btn {
  font-family: $font-regular !important;
  font-weight: 500 !important;
  outline: none !important;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 14px !important;

  &.btn-white {
    background: $white;
    color: #2C2E34;
    font-family: $font-main-bold !important;
    border-radius: 8px;
    border: 2px solid rgba(93, 122, 107, 0.50);

    &:hover {
      color: $primary;
      border: 2px solid rgb(93, 122, 107);
    }


  }

  &.btn-transparent {
    background: transparent;
    color: $white;
    border: 1px solid $white;
  }

  &.btn-primary-inverse {
    padding: 15px 10px;
    background: $white;
    color: $primary;
    border-color: $primary;

    &.bdr {
      border-color: $primary;
    }

    &:hover {
      background: $primary;
      color: $white;
    }

    &:focus {
      background: $primary;
      color: $white;
    }

    &:active {
      background: $primary;
      color: $white;
    }
  }

  &.btn-dark {
    background: $black;
    color: $white;
    border: unset;

    &:hover {
      background: $white;
      color: $black;
    }

    &:focus {
      background: $white;
      color: $black;
    }

    &:active {
      background: $white;
      color: $black;
    }
  }

  &.btn-light-green {
    background: #D2DECD;
    color: #2C2E34;
    text-align: center;
    font-family: $font-regular;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  &.btn-primary {
    background: $primary;
    border: 1px solid $primary;
    color: $white;
    padding: 10px 23px;

    &.lg {
      font-size: 18px !important;
    }

    &.sm {
      padding: 4px 8px;
      font-size: 15px;
    }

    &.call {
      &:hover {
        background: $primary;
        border: 1px solid $primary;
        color: $white;
      }
    }

    &:hover {
      background: $white;
      color: $primary;
    }

    &:focus {
      background: $white;
      color: $primary;
    }

    &:active {
      background: $white;
      color: $primary;
    }
  }

  &.btn-choco {
    background: $choco;
    border: 1px solid $choco;
    color: $white;
    padding: 10px 1rem;

    &:hover {
      background: $white;
      color: $choco;
    }

    &:focus {
      background: $white;
      color: $choco;
    }

    &:active {
      background: $white;
      color: $choco;
    }
  }

  &.btn-black {
    &.p-40{
      padding: 22px 40px 22px 40px !important;

      &::after {
        left: calc(100% - 76%) !important;
        transform: translate(-15px, -50%) !important;
      }
      &:hover::after {
        right: 25px;
        transform: translate(95px, -50%) !important;
      }
    }
    margin-top: 1rem;
    font-size: 11px;
    line-height: 1.8;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    max-width: 100%;
    border: 1px solid;
    border-radius: 0;
    padding: 22px 40px 22px 68px;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: padding 0.4s;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-image: none;
    position: relative;
    z-index: 0;
    color: rgba(254, 254, 254, 1) !important;
    background-color: rgba(20, 20, 20, 0.98) !important;
    border-color: rgba(20, 20, 20, 1) !important;

    &:hover {
      color: #ffffff !important;
      background-color: #000000 !important;
      border-color: rgba(0, 0, 0, 1) !important;
      padding-left: 40px;
      padding-right: 68px;

    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: inherit;
      z-index: -1;
      transform: translateX(0px);
      transition: transform 0.4s ease, background-color 0s linear 0.4s,
      left 0s linear 0.4s;
    }

    &::after {
      content: "";
      width: 8px;
      height: 14px;
      background-color: currentColor;
      display: block;
      position: absolute;
      right: 40px;
      left: calc(100% - 82%);
      top: 50%;
      transform: translate(-15px, -50%);
      -webkit-clip-path: path(
                      "M8 7.00065C8 7.00065 3.02079 9.96201 0.214319 13.6673L0 13.4718C0.8 11.7051 1.7127 9.15469 4.12194 7.00065C1.7127 4.8448 0.8 2.29434 0 0.527667L0.216166 0.333984C3.02263 4.03387 8 7.00065 8 7.00065Z"
      );
      clip-path: path(
                      "M8 7.00065C8 7.00065 3.02079 9.96201 0.214319 13.6673L0 13.4718C0.8 11.7051 1.7127 9.15469 4.12194 7.00065C1.7127 4.8448 0.8 2.29434 0 0.527667L0.216166 0.333984C3.02263 4.03387 8 7.00065 8 7.00065Z"
      );
      transition: transform 0.4s ease, left 0s linear 0.2s;
    }

    &:hover::before {
      transform: translateX(100%);
      left: -100%;
      transition: transform 0.4s ease, background-color 0s linear 0.4s,
      left 0s linear 0.4s;
    }

    &:hover::after {
      right: 25px;
      transform: translate(179px, -50%);
    }
  }

  //.newButtonS {
  //  margin-top: 2rem;
  //  font-size: 11px;
  //  line-height: 1.8;
  //  letter-spacing: 0.4px;
  //  text-transform: uppercase;
  //  max-width: 100%;
  //  border: 1px solid;
  //  border-radius: 0;
  //  padding: 22px 40px 22px 40px;
  //  overflow: hidden;
  //  text-overflow: ellipsis;
  //  transition: padding 0.4s;
  //  display: inline-block;
  //  text-decoration: none;
  //  text-align: center;
  //  touch-action: manipulation;
  //  cursor: pointer;
  //  -webkit-user-select: none;
  //  -moz-user-select: none;
  //  user-select: none;
  //  background-image: none;
  //  position: relative;
  //  z-index: 0;
  //  color: rgba(254, 254, 254, 1) !important;
  //  background-color: rgba(20, 20, 20, 0.98) !important;
  //  border-color: rgba(20, 20, 20, 1) !important;
  //
  //  &:hover {
  //    color: #ffffff !important;
  //    background-color: #000000 !important;
  //    border-color: rgba(0, 0, 0, 1) !important;
  //    padding-left: 40px;
  //    padding-right: 40px;
  //
  //  }
  //
  //  &::before {
  //    content: "";
  //    display: block;
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 100%;
  //    background-color: inherit;
  //    z-index: -1;
  //    transform: translateX(0px);
  //    transition: transform 0.4s ease, background-color 0s linear 0.4s,
  //    left 0s linear 0.4s;
  //  }
  //
  //  &::after {
  //    content: "";
  //    width: 8px;
  //    height: 14px;
  //    background-color: currentColor;
  //    display: block;
  //    position: absolute;
  //    right: 40px;
  //    left: calc(100% - 78%);
  //    top: 50%;
  //    transform: translate(-15px, -50%);
  //    -webkit-clip-path: path(
  //                    "M8 7.00065C8 7.00065 3.02079 9.96201 0.214319 13.6673L0 13.4718C0.8 11.7051 1.7127 9.15469 4.12194 7.00065C1.7127 4.8448 0.8 2.29434 0 0.527667L0.216166 0.333984C3.02263 4.03387 8 7.00065 8 7.00065Z"
  //    );
  //    clip-path: path(
  //                    "M8 7.00065C8 7.00065 3.02079 9.96201 0.214319 13.6673L0 13.4718C0.8 11.7051 1.7127 9.15469 4.12194 7.00065C1.7127 4.8448 0.8 2.29434 0 0.527667L0.216166 0.333984C3.02263 4.03387 8 7.00065 8 7.00065Z"
  //    );
  //    transition: transform 0.4s ease, left 0s linear 0.2s;
  //  }
  //
  //  &:hover::before {
  //    transform: translateX(100%);
  //    left: -100%;
  //    transition: transform 0.4s ease, background-color 0s linear 0.4s,
  //    left 0s linear 0.4s;
  //  }
  //
  //  &:hover::after {
  //    right: 25px;
  //    transform: translate(95px, -50%);
  //  }
  //}
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background: $white;
  color: $primary;
}

.clicked-btn {
  background-color: #211f20;
  border-color: #ffffff;
  color: #ffffff;
}

.disabled-btn {
  pointer-events: none !important;
}

.input-group {
  z-index: 0;
}


.link-dark {
  color: #2C2E34;
  text-align: center;
  font-family: $font-main-bold;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.btn-bordered-white {
  border-radius: 8px;
  border: 1px solid #FFF;
  color: #FFF;
  font-family: $font-regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 7px 10px !important;

  &:hover {
    background: #fff;
    color: #2C2E34 !important;
  }

  &.active{
    background: #fff;
    color: #2C2E34 !important;
  }
}