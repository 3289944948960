html,
body {
  font-family: "Quicksand Regular", sans-serif;
  margin-bottom: 0;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Palatino Linotype Bold";
}

a:hover {
  text-decoration: none !important;
  cursor: pointer;
}

.h-150 {
  height: 150px;
}

.h-200 {
  height: 200px;
}

.tawk-toolbar {
  display: none !important;
}

.w-35 {
  width: 35%;
}

.data-p {
  padding: 20px 10px;
}

.bdr-tl {
  border-top-left-radius: 5px;
}

.bdr-tr {
  border-top-right-radius: 5px;
}

.bdr-br {
  border-bottom-right-radius: 5px;
}

.bdr-bl {
  border-bottom-left-radius: 5px;
}

.p-5 {
  padding: 5px !important;
}

.h-350 {
  height: 350px;
}

.bdr-50 {
  border-radius: 50%;
}

.d-block {
  display: block !important;
}

.aside-img {
  height: 175px;
  object-fit: cover;
}

.horizontal-img {
  height: 250px;
  object-fit: cover;
}

.h-50px {
  height: 50px;
}

.h-46px {
  height: 46px !important;
}

.h-47px {
  height: 47px !important;
}

.w-90 {
  width: 90%;
}

.h-600px {
  height: 600px;
}

//.description{
//    position: absolute;
//
//    right: 50%;
//    transform: translate(50%, -50%);
//}
.h-80 {
  height: 80%;
}

.bottom-3 {
  bottom: 3%;
}

.bottom-7 {
  bottom: 7%;
}

.bdr-primary {
  border: 1px solid $primary;
}

.h-main-slider {
  height: calc(132vh - 100px);
}

.carousel-indicators {
  bottom: 5%;
}

.left-0 {
  left: 0 !important;
}

.about-info {
  position: absolute;
  z-index: 99;
  bottom: 280px;
}

.nb-block {
  position: absolute;
  width: 75%;
  right: 50%;
  top: 80%;
  transform: translate(50%, 0%);
}

.img-wrapper {
  img {
    width: 100%;
    max-width: 400px;
  }
}

.top-30px {
  top: 30px;
}

.right-20px {
  right: 20px;
}

.mt-65px {
  margin-top: 65px;
}

p {
  margin-bottom: 0;
}

.mode {
  cursor: pointer;
  margin-top: 6px;
}

.mode:hover {
  color: #3bbab1 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.h-390 {
  height: 390px;
}

.obj-fit-cover {
  object-fit: cover;
}

.w-50px {
  width: 50px;
}

.bdr-w-r {
  border-right: 1px solid $white;
}

.stat-img {
  width: 60px;
  height: 65px;
  object-fit: contain;
}

.h-55 {
  height: 55px;
}

.bdr-w {
  border: 1px solid white;
}

.h-310 {
  height: 310px;
}



.z-index-999 {
  z-index: 99999;
}

.fv-plugins-message-container {
  color: #dc3545;
  font-size: 12px;
  font-weight: bold;
}

.content-block {
  padding: 25px 38px;

  &.sm {
    padding: 15px 20px;
  }

  .date {
    color: #2C2E34;
    font-family: $font-regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    opacity: 0.5;

    &.sm {
      font-size: 14px;
    }
  }
}

.date {
  color: #2C2E34;
  font-family: $font-regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  opacity: 0.5;

  &.sm {
    font-size: 14px;
  }
}

.article-block {
  padding: 38px 28px;
}

.h-200 {
  height: 200px;
}

.invalid-select {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.valid-select {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #198754 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.right-0 {
  right: 0;
}

.br-8px {
  border-radius: 8px;
}