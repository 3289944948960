input, textarea {
  &.form-control {
    &:focus {
      box-shadow: none;
    }

    &.newsletter {
      border-color: rgba(93, 121, 107, 0.4);
      color: #898989;
      font-family: $font-regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 214.286% */
      text-transform: capitalize;

    }

    &.is-invalid {
      border-color: #dc3545 !important;
    }

    &.is-valid {
      border-color: #198754 !important;
    }

  }

  &.input-form {
    border-color: $primary;
    border-radius: 0;
    font-size: 12px;
    text-indent: 10px;
    height: 45px;
  }

  &.textarea-form {
    border-color: $primary;
    border-radius: 0;
    font-size: 12px;
    text-indent: 10px;
    height: 150px;
    padding-top: 15px;
  }
}


.form-group {
  label.error {
    position: absolute;
    color: #dc3545
  }

  .custom-error {
    position: absolute;
    color: #dc3545;
    display: none;
    font-size: 15px;
    font-weight: bold;
  }


}

.custom-pagination {
  &.resale-pagination {
    .pagination li:not(:first-child):not(:last-child) {
      //display: none;
    }
  }
}

.resale-pagination {
  .pagination {
    justify-content: center;

    li:not(:first-child):not(:last-child) {
      color: #2C2E34;
      border-radius: 3px;
      border: 1px solid #87878B;
      height: 27px;
      width: 27px;
      margin-right: 5px;
      margin-left: 2px;
      text-align: center;
      padding: 2px;
      font-family: $font-main-bold;
    }

    .active {
      border-radius: 3px;
      background: #A57E75;
      color: #fff !important;
    }

    .next {
      .custom-ar {
        position: absolute;
        display: block;
        top: 55%;
        right: -2%;

        img {
          width: 40px
        }
      }

      .chev-right {
        color: #fff;
        border-radius: 3px;
        border: 1px solid #87878B;
        height: 27px;
        width: 27px;
        margin-right: 5px;
        margin-left: 2px;
        text-align: center;
        padding: 2px;
        background: #A57E75;
        font-size: 10px;
      }

      &.disabled {
        opacity: 0.3;

        .chev-right {
          opacity: 1;
          background: #909093;
          color: white;
        }
      }

    }

    .previous {
      .custom-ar {
        display: block;
        position: absolute;
        top: 55%;
        left: -2%;

        img {
          width: 40px
        }
      }

      .chev-left {
        color: #fff;
        border-radius: 3px;
        border: 1px solid #87878B;
        height: 27px;
        width: 27px;
        margin-right: 5px;
        margin-left: 2px;
        text-align: center;
        padding: 2px;
        background: #A57E75;
        font-size: 10px;
      }

      &.disabled {
        opacity: 0.3;

        .chev-left {
          opacity: 1;
          background: #909093;
          color: white;
        }
      }
    }

  }

}

.custom-pagination {
  .next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);

    &.disabled {
      opacity: 0.3;
    }
  }

  .previous {
    position: absolute;
    top: 50%;
    left: 3%;
    transform: translate(-50%, -50%);

    &.disabled {
      opacity: 0.3;
    }
  }

  .pagination {
    justify-content: center;

    li:not(:first-child):not(:last-child) {
      color: transparent;
      border-radius: 50%;
      border: 1px solid #5D7A6B;
      height: 10px;
      width: 10px;
      margin-right: 2px;
      margin-left: 2px;
    }

    .active {
      border-radius: 3px;
      background: #5D7A6B;
    }
  }
}

.main-input {
  color: #898989;
  font-family: $font-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 214.286% */
  text-transform: capitalize;
  border-radius: 8px;
  background: #FFF;
  border: unset;
  height: 50px;

  &.bordered {
    border-radius: 8px;
    border: 1px solid var(--Grey, #C5CCCB);
    opacity: 0.6;
    background: #FFF;
  }
}

.invalid-select {
  border: 1px solid var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.valid-select {
  border: 1px solid var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.request-consultant {
  .css-t3ipsp-control {
    height: 50px;
  }
}

.form-property {
  .css-t3ipsp-control {
    height: 50px;
  }

  .css-13cymwt-control {
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }

  .css-1u9des2-indicatorSeparator {
    display: none
  }

  .invalid-select, .valid-select {
    border-radius: 8px;
  }
}
